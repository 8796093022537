import type { AxiosResponse } from 'axios'
import http, { convertRequestData } from '~/http-common'
import type { HttpResponseData } from '~~/types/httpResponseData'
import type { RegisterTermsOfUseRes } from '~~/types/registerTermsOfUseRes'
import type { AnnounceJsonRes } from '~~/types/announceJsonRes'
import { API_URL } from '~/const/env'
import type { Child } from '~~/types/child'
import { makeQueryStrings } from '~/libs/makeQueryStrings'
import { ApiPath } from '~/enum/apiPath'
import { FrontEnvUrl } from '~/utils/frontEnvUrl'

interface LoginResData extends HttpResponseData {
  url: string,
}

interface LoginRes extends AxiosResponse {
  data: LoginResData,
}

interface GetClassroomsRes extends HttpResponseData {
  f40013DtoList: {
    orgId: string,
    orgNm: string,
    roleDiv: number,
    disagreeFlg: string,
    isAvailableEnglish: boolean,
  }[],
  orgNow: string, // 選択中の組織ID。空文字の場合もある
}

interface TermsOfUseResData extends HttpResponseData {
  updDatime: string,
  gidFlg: string,
  gidRuleFlg: string,
  manaRuleFlg: string,
  roleDiv: string,
}

class LoginApiService {
  login = async (username: string, password: string): Promise<LoginRes> => {
    const postData: Map<string, string> = new Map([
      ['loginUrl', FrontEnvUrl.getEnvFrontUrl(API_URL) + ApiPath.LOGIN_MANAMIRU_FRONT],
      ['equipment','otherEquip'],
      ['brandCd','manamiru'],
    ])
    postData.set('username', username)
    postData.set('password', password)
    return await http.post(ApiPath.LOGIN_API_LOGIN, convertRequestData(postData))
  }

  getClassrooms = async (): Promise<GetClassroomsRes> => {
    const res = await http.get(ApiPath.LOGIN_API_GET_CLASSROOMS)
    return res.data
  }

  selectClassroom = async (orgId: string): Promise<LoginRes> => {
    const data = 'orgId=' + orgId
    return await http.post(ApiPath.LOGIN_API_SELECT_CLASSROOM, data)
  }

  selectStudent = async (student: Child): Promise<LoginRes> => {
    const data = {
      stuId: student.userId,
      orgId: student.orgId,
    }
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const queryStrings = makeQueryStrings(data)
    return http.get(ApiPath.LOGIN_API_SELECT_STUDENT + queryStrings, { headers: headers })
  }

  initTermsOfUse = async (): Promise<TermsOfUseResData> => {
    const path = ApiPath.LOGIN_API_INIT_TERMS_OF_USE
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    return (await http.post(path, [], { headers: headers })).data
  }

  registerTermsOfUse = async (updDatime: string, gidFlg: boolean, manaFlg: boolean): Promise<RegisterTermsOfUseRes> => {
    const path = ApiPath.LOGIN_API_REGISTER_TERMS_OF_USE
    const data = 'updDatime=' + updDatime + '&gidFlg=' + gidFlg.toString() + '&manaFlg=' + manaFlg.toString()
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const response: RegisterTermsOfUseRes = await http.post(path, data, { headers: headers })
    return response
  }

  getAnnounceInfo = async (): Promise<AnnounceJsonRes> => {
    const path = ApiPath.ANNOUNCE_JSON
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    return (await http.get(path, { headers: headers }))
  }
}

export default new LoginApiService()

import { default as indexGNpb5yXeX0Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/calendar/index.vue?macro=true";
import { default as index5cSyYP5i5KMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/contents-accessctrl/index.vue?macro=true";
import { default as index5oVLsF7u5HMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-guard/index.vue?macro=true";
import { default as index9H597LhgZNMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-manager/index.vue?macro=true";
import { default as indexgXMptlQmY0Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/index.vue?macro=true";
import { default as indexfqxlNYGZtLMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/accesscode/index.vue?macro=true";
import { default as indexJAcitPyl8HMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/category/index.vue?macro=true";
import { default as indexahQY5If8HcMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/index.vue?macro=true";
import { default as indext5aSy3mZN2Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/qr-reader/index.vue?macro=true";
import { default as indexWksQH30jUAMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/qr-check/index.vue?macro=true";
import { default as indextoG2Birrd3Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/contact/index.vue?macro=true";
import { default as indexwBoi0lC53wMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/edit/[tabName]/index.vue?macro=true";
import { default as indexNk5vyjdcYWMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/overview/index.vue?macro=true";
import { default as index393x9h0WJ9Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/edit/index.vue?macro=true";
import { default as indexE2QBPt1BrJMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/preview/index.vue?macro=true";
import { default as indexWswuNUeLX7Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[tabName]/index.vue?macro=true";
import { default as indexO5ihDU8PUxMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/index.vue?macro=true";
import { default as indexGzMq8wRLemMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/index.vue?macro=true";
import { default as registerjGxLsDlvNRMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/register.vue?macro=true";
import { default as indexqOioseyHD3Meta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/events/index.vue?macro=true";
import { default as indextAAK3ogMLNMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/[noticeId]/index.vue?macro=true";
import { default as indexxIT74tn3yOMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/index.vue?macro=true";
import { default as index4GQBff4NtQMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/index.vue?macro=true";
import { default as indexxiaPedMGTkMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[messageId]/index.vue?macro=true";
import { default as indexE2i0OmSVnJMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[noticeType]-[noticeId]/index.vue?macro=true";
import { default as indexMG2utRjdQwMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/index.vue?macro=true";
import { default as indexkyFkHUM0vnMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/help/index.vue?macro=true";
import { default as indextVs5WLQxzTMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/index.vue?macro=true";
import { default as indexp2ev45KTMwMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/register-entrance-exit/index.vue?macro=true";
import { default as indexjk0czNDVuuMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/send-message/index.vue?macro=true";
import { default as indexQpezcfhD6vMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/index.vue?macro=true";
import { default as indexsfF7JvRkxsMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/index.vue?macro=true";
import { default as indexQchvuISJjnMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/complete/index.vue?macro=true";
import { default as index9FKjfzemGoMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/confirm/index.vue?macro=true";
import { default as indexAYSP1QIXqrMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/input/index.vue?macro=true";
import { default as loadinglnTIrOEZhNMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/loading.vue?macro=true";
import { default as indexwcorvnIWAPMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/change-password/index.vue?macro=true";
import { default as indextdDOzTVKntMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/index.vue?macro=true";
import { default as indexWL5PXiGxrbMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-classroom/index.vue?macro=true";
import { default as indexFFcffrOI1BMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-student/index.vue?macro=true";
import { default as indexyqvdQCuRLZMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/terms-of-use/index.vue?macro=true";
import { default as indexSZvIkWkEHkMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/messages/index.vue?macro=true";
import { default as index6IZPMIfgMJMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/confirm-notification-details/index.vue?macro=true";
import { default as index5X6tahsRDTMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/input-notification-details/index.vue?macro=true";
import { default as indexOvRWbafmHrMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/show-guard-read-status/index.vue?macro=true";
import { default as indexmMxWITEAujMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/index.vue?macro=true";
import { default as indexmc4lZc6IhmMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/entrance-exit-history/index.vue?macro=true";
import { default as indexiLuav5pC4aMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/event-management/index.vue?macro=true";
import { default as indexJBKl3cPSYRMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/index.vue?macro=true";
import { default as indexAoIF0iA3VZMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/version-info/index.vue?macro=true";
import { default as index2CgWqJJAkgMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/index.vue?macro=true";
import { default as indexHE1vq3FbXDMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/profile/index.vue?macro=true";
import { default as indexGRZlf9tmkJMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/version-info/index.vue?macro=true";
export default [
  {
    name: "calendar",
    path: "/calendar",
    meta: indexGNpb5yXeX0Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/calendar/index.vue")
  },
  {
    name: "cloudroom-contents-accessctrl",
    path: "/cloudroom/contents-accessctrl",
    meta: index5cSyYP5i5KMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/contents-accessctrl/index.vue")
  },
  {
    name: "cloudroom-history-guard",
    path: "/cloudroom/history-guard",
    meta: index5oVLsF7u5HMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-guard/index.vue")
  },
  {
    name: "cloudroom-history-manager",
    path: "/cloudroom/history-manager",
    meta: index9H597LhgZNMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-manager/index.vue")
  },
  {
    name: "cloudroom",
    path: "/cloudroom",
    meta: indexgXMptlQmY0Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/index.vue")
  },
  {
    name: "cloudroom-materials-accesscode",
    path: "/cloudroom/materials/accesscode",
    meta: indexfqxlNYGZtLMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/accesscode/index.vue")
  },
  {
    name: "cloudroom-materials-category",
    path: "/cloudroom/materials/category",
    meta: indexJAcitPyl8HMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/category/index.vue")
  },
  {
    name: "cloudroom-materials",
    path: "/cloudroom/materials",
    meta: indexahQY5If8HcMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/index.vue")
  },
  {
    name: "cloudroom-materials-qr-reader",
    path: "/cloudroom/materials/qr-reader",
    meta: indext5aSy3mZN2Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/qr-reader/index.vue")
  },
  {
    name: "cloudroom-qr-check",
    path: "/cloudroom/qr-check",
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/qr-check/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indextoG2Birrd3Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/contact/index.vue")
  },
  {
    name: "event-manage-eventId-edit-tabName",
    path: "/event-manage/:eventId()/edit/:tabName()",
    meta: indexwBoi0lC53wMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/edit/[tabName]/index.vue")
  },
  {
    name: "event-manage-eventId-overview",
    path: "/event-manage/:eventId()/overview",
    meta: indexNk5vyjdcYWMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/overview/index.vue")
  },
  {
    name: "event-manage-eventTemplateId-edit",
    path: "/event-manage/:eventTemplateId()/edit",
    meta: index393x9h0WJ9Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/edit/index.vue")
  },
  {
    name: "event-manage-eventTemplateId-preview",
    path: "/event-manage/:eventTemplateId()/preview",
    meta: indexE2QBPt1BrJMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/preview/index.vue")
  },
  {
    name: "event-manage-tabName",
    path: "/event-manage/:tabName()",
    meta: indexWswuNUeLX7Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[tabName]/index.vue")
  },
  {
    name: "event-manage",
    path: "/event-manage",
    meta: indexO5ihDU8PUxMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/index.vue")
  },
  {
    name: "events-eventId",
    path: "/events/:eventId()",
    meta: indexGzMq8wRLemMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId-register",
    path: "/events/:eventId()/register",
    meta: registerjGxLsDlvNRMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/register.vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexqOioseyHD3Meta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/events/index.vue")
  },
  {
    name: "guard-home-channels-noticeId",
    path: "/guard-home/channels/:noticeId()",
    meta: indextAAK3ogMLNMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/[noticeId]/index.vue")
  },
  {
    name: "guard-home-channels",
    path: "/guard-home/channels",
    meta: indexxIT74tn3yOMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/index.vue")
  },
  {
    name: "guard-home",
    path: "/guard-home",
    meta: index4GQBff4NtQMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/index.vue")
  },
  {
    name: "guard-home-notifications-messageId",
    path: "/guard-home/notifications/:messageId()",
    meta: indexxiaPedMGTkMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[messageId]/index.vue")
  },
  {
    name: "guard-home-notifications-noticeType-noticeId",
    path: "/guard-home/notifications/:noticeType()-:noticeId()",
    meta: indexE2i0OmSVnJMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[noticeType]-[noticeId]/index.vue")
  },
  {
    name: "guard-home-notifications",
    path: "/guard-home/notifications",
    meta: indexMG2utRjdQwMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/index.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: indexkyFkHUM0vnMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/help/index.vue")
  },
  {
    name: "home-stuId",
    path: "/home/:stuId()",
    meta: indextVs5WLQxzTMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/index.vue")
  },
  {
    name: "home-stuId-register-entrance-exit",
    path: "/home/:stuId()/register-entrance-exit",
    meta: indexp2ev45KTMwMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/register-entrance-exit/index.vue")
  },
  {
    name: "home-stuId-send-message",
    path: "/home/:stuId()/send-message",
    meta: indexjk0czNDVuuMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/send-message/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: indexQpezcfhD6vMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/index.vue")
  },
  {
    name: "inquiry-complete",
    path: "/inquiry/complete",
    meta: indexQchvuISJjnMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/complete/index.vue")
  },
  {
    name: "inquiry-confirm",
    path: "/inquiry/confirm",
    meta: index9FKjfzemGoMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/confirm/index.vue")
  },
  {
    name: "inquiry-input",
    path: "/inquiry/input",
    meta: indexAYSP1QIXqrMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/input/index.vue")
  },
  {
    name: "loading",
    path: "/loading",
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/loading.vue")
  },
  {
    name: "login-change-password",
    path: "/login/change-password",
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/change-password/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indextdDOzTVKntMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/index.vue")
  },
  {
    name: "login-select-classroom",
    path: "/login/select-classroom",
    meta: indexWL5PXiGxrbMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-classroom/index.vue")
  },
  {
    name: "login-select-student",
    path: "/login/select-student",
    meta: indexFFcffrOI1BMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-student/index.vue")
  },
  {
    name: "login-terms-of-use",
    path: "/login/terms-of-use",
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/terms-of-use/index.vue")
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexSZvIkWkEHkMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/messages/index.vue")
  },
  {
    name: "notifications-noticeId-confirm-notification-details",
    path: "/notifications/:noticeId()/confirm-notification-details",
    meta: index6IZPMIfgMJMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/confirm-notification-details/index.vue")
  },
  {
    name: "notifications-noticeId-input-notification-details",
    path: "/notifications/:noticeId()/input-notification-details",
    meta: index5X6tahsRDTMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/input-notification-details/index.vue")
  },
  {
    name: "notifications-noticeId-show-guard-read-status",
    path: "/notifications/:noticeId()/show-guard-read-status",
    meta: indexOvRWbafmHrMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/show-guard-read-status/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexmMxWITEAujMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/index.vue")
  },
  {
    name: "others-entrance-exit-history",
    path: "/others/entrance-exit-history",
    meta: indexmc4lZc6IhmMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/entrance-exit-history/index.vue")
  },
  {
    name: "others-event-management",
    path: "/others/event-management",
    meta: indexiLuav5pC4aMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/event-management/index.vue")
  },
  {
    name: "others",
    path: "/others",
    meta: indexJBKl3cPSYRMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/index.vue")
  },
  {
    name: "others-version-info",
    path: "/others/version-info",
    meta: indexAoIF0iA3VZMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/version-info/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: index2CgWqJJAkgMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/index.vue")
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    meta: indexHE1vq3FbXDMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/profile/index.vue")
  },
  {
    name: "settings-version-info",
    path: "/settings/version-info",
    meta: indexGRZlf9tmkJMeta || {},
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/version-info/index.vue")
  }
]
import { envEnum } from '~/enum/envEnum';
import { envFrontEnum } from '~/enum/envFrontEnum'

export class FrontEnvUrl {
    static getEnvFrontUrl(envUrl: string): string | undefined {
        // `envEnum` からキーを取得
        const envKey = (Object.keys(envEnum) as Array<keyof typeof envEnum>).find(
            key => envEnum[key] as string === envUrl
        )

        // `envFrontEnum` から対応する値を返却
        return envKey ? envFrontEnum[envKey] : undefined;
    }
}

<template>
  <div
    class="
      flex
      flex-col
      h-screen
      layout
      layout-view
      w-screen
    "
  >
    <!-- ヘッダー -->
    <header
      class="
        common-background-leaf
        fixed
        w-full
        z-layouts-header
      "
    >
      <common-header-guard
        :page-title="data.pageTitle"
        :top-page-flg="data.topPageFlg"
        @history-back="checkMaterialPage()"
        @logout="confirmLogout()"
        @switch-student="showSelectStudentModal()"
      >
      </common-header-guard>
    </header>
    <!-- コンテンツ -->
    <main
      class="
        flex-1
      "
    >
      <slot />
    </main>
    <!-- フッター -->
    <footer
      v-if="(!osChecker.isBrowser() && (useAppStatusGuardStore().getSelectedChild().disagreeFlg === null || useAppStatusGuardStore().getSelectedChild().disagreeFlg === '' || useAppStatusGuardStore().getSelectedChild().disagreeFlg === '0')) && !data.hideFooterFlg"
      :key="useAppStatusGuardStore().getSelectedChild().userId"
      class="
        bottom-0
        common-background-leaf
        fixed
        w-full
        z-layouts-footer
      "
    >
      <common-global-nav-guard
        :pageType="data.pageType"
      >
      </common-global-nav-guard>
    </footer>
    <footer
      v-else
      class="
        bottom-0
        fixed
        h-12
        invisible
        w-full
        z-layouts-footer
      "
    >
      dummy
    </footer>
    <page-guard-home-select-student-modal
      v-if="isSelectStudentModal"
      :close-btn-flg="true"
      :child-list="useAppStatusGuardStore().getChildren()"
      :selected-child="useAppStatusGuardStore().getSelectedChild()"
      @submit="($event: Child) => submitSelectStudentModal($event)"
      @close="closeSelectStudentModal()"
    >
    </page-guard-home-select-student-modal>
    <common-loading
      v-if="isLoading"
    />
    <common-modal-error
      v-if="isError"
      :error-title="errorTitle"
      :error-message="errorMessage"
      :error-code="errorCode"
      role="guard"
      @close="($event: boolean) => closeErrorModal($event)"
    >
    </common-modal-error>
    <common-modal-logout
      v-if="isShowLogoutModal"
      @cancel="closeConfirmModal()"
      @logout="logoutEvent()"
      role="guard"
    >
    </common-modal-logout>
    <common-modal
      v-if="isShowBackModal"
      @close="closeBackModal()"
      header-text="確認"
      modal-margin-x="mx-4"
      modal-height="h-auto"
      role="guard"
    >
      <template
        v-slot:body
      >
        <div
          class="
            common-text-gray
            font-semibold
            text-center
          "
        >
            保護者用の画面に戻ります。<br>よろしいですか？
        </div>
      </template>
      <template
        v-slot:control
      >
        <div
          class="
            flex
            my-2
          "
        >
          <common-btn
            btn-type="tertiary"
            class="
              mx-1
              sp:text-[3vw]
              tab:text-base
              w-[36vw]
            "
            @click="closeBackModal()"
          >
            キャンセル
          </common-btn>
          <common-btn
            btn-type="septenary"
            class="
              mx-1
              sp:text-[3vw]
              tab:text-base
              w-[36vw]
            "
            @click="checkMaterialPage()"
          >
            OK
          </common-btn>
        </div>
      </template>
    </common-modal>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, provide, reactive, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import type { RouteLocationNormalizedLoaded } from 'vue-router'
  import type { GuardPageData } from '~~/types/guardPageData'
  import type { GuardPageType } from '~~/types/guardPageType'
  import type { Child } from '~~/types/child'
  import { historyBack } from '~/libs/historyBack'
  import { logout } from '~/libs/logout'
  import { layoutsSetting } from '~/libs/layoutsSetting'
  import { updateIsLoadingKey } from '~/const/updateIsLoadingKey'
  import { updateIsErrorKey, updateErrorTitleKey, updateErrorMessageKey, updateErrorCodeKey } from '~/const/updateErrorKey'
  import { modalDisplaySwitching } from '~/libs/modalDisplaySwitching'
  import { useAppStatusGuardStore } from '~/store/pinia/app-status-guard'
  import LoginApiService from '~/services/LoginApiService'
  import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
  import { getIsBackgroundLoadingKey } from '~/const/getIsBackgroundLoadingKey'
  import { OsChecker } from '~/utils/osChecker'
  import { httpInterceptors } from '~/libs/httpInterceptors'
  import { checkVisibilityChange } from '~/utils/check-visibility-change'
  import type { AppStatusGuardRes } from '~~/types/appStatusGuardRes'

  const route = useRoute()
  const router = useRouter()

  checkVisibilityChange('3')

  watch(route, (to) => {
    setPageInfo(to)
  })

  const osChecker = new OsChecker

  const data: GuardPageData = reactive({
    pageTitle: '',
    pageType: 'guard-home',
    topPageFlg: true,
    hideFooterFlg: false,
  })

  const setPageInfo = (route: RouteLocationNormalizedLoaded) => {
    document.title = route?.meta?.pageTitle as string
    data.pageType = route?.meta?.pageType as GuardPageType
    data.pageTitle = route?.meta?.pageTitle as string
    data.topPageFlg = route?.meta?.topPageFlg as boolean
    data.hideFooterFlg = route?.meta?.hideFooterFlg as boolean
  }

  setPageInfo(route)

  const submitSelectStudentModal = (async (student: Child) => {
    updateIsLoading?.(true)
    // LoginAPIServiceを実行
    await LoginApiService.selectStudent(student).then(async (response) => {
      if (response.data.code == 0) {
        if (response.headers.token !== undefined) {
          sessionStorage.setItem('token', response.headers.token as string)
        }
        // 選択した生徒をStoreに設定
        useAppStatusGuardStore().setSelectedChild(student)
        // モーダルを非表示
        closeSelectStudentModal()

        const currentPath = router.currentRoute.value.fullPath
        await router.replace({ path: '/loading'}).then(async () => {
          await router.replace({ path: currentPath })
        })
      } else {
        // エラーモーダルを表示
        updateIsError?.(true)
      }
      updateIsLoading?.(false)
    })
  })

  const isSelectStudentModal = ref(false)
  const showSelectStudentModal = () => {
    modalDisplaySwitching(isSelectStudentModal, true)
    // 表示時に生徒を再読み込み（未読件数更新のため）
    useAppStatusGuardStore().loadChildren().then((result: AppStatusGuardRes) => {
      if (result.data.code != 0) {
        modalDisplaySwitching(isSelectStudentModal, false)
        updateIsError?.(true)
      }
    })
    .catch(() => {
      modalDisplaySwitching(isSelectStudentModal, false)
      updateIsError?.(true)
    })

  }

  // マナミルスタディ画面から戻る際に確認ダイアログを表示
  const checkMaterialPage = async () => {
    if (route.path == '/cloudroom' && !isShowBackModal.value) {
      confirmBack()
    } else {
      isShowBackModal.value = false
      await historyBack(router)
    }
  }
  const closeSelectStudentModal = () => {
    modalDisplaySwitching(isSelectStudentModal, false)
  }

  const isShowLogoutModal = ref(false)
  const confirmLogout = () => {
    modalDisplaySwitching(isShowLogoutModal, true)
  }
  const closeConfirmModal = () => {
    modalDisplaySwitching(isShowLogoutModal, false)
  }
  const logoutEvent = async () => {
    await logout(router)

    closeConfirmModal()
  }

  const isShowBackModal = ref(false)
  const confirmBack = () => {
    modalDisplaySwitching(isShowBackModal, true)
  }
  const closeBackModal = () => {
    modalDisplaySwitching(isShowBackModal, false)
  }

  onMounted(() => {
    layoutsSetting(false, false)
  })

  const isLoading = ref(false)
  const updateIsLoading = (b: boolean) => isLoading.value = b
  provide(updateIsLoadingKey, updateIsLoading)

  const isError = ref(false)
  const updateIsError = (b: boolean) => isError.value = b
  provide(updateIsErrorKey, updateIsError)

  const errorTitle = ref('')
  const updateErrorTitle = (s: string) => errorTitle.value = s
  provide(updateErrorTitleKey, updateErrorTitle)

  const errorMessage = ref('')
  const updateErrorMessage = (s: string) => errorMessage.value = s
  provide(updateErrorMessageKey, updateErrorMessage)

  const errorCode = ref('')
  const updateErrorCode = (s: string) => errorCode.value = s
  provide(updateErrorCodeKey, updateErrorCode)

  const isBackgroundLoading: Map<string, boolean> = reactive(new Map<string, boolean>())
  const updateIsBackgroundLoading = (key: string, b: boolean) => isBackgroundLoading.set(key, b)
  provide(updateIsBackgroundLoadingKey, updateIsBackgroundLoading)
  const getIsBackgroundLoading = () => isBackgroundLoading
  provide(getIsBackgroundLoadingKey, getIsBackgroundLoading)

  const closeErrorModal = async (isSystemError: boolean) => {
    updateIsError?.(false)
    updateErrorTitle?.('')
    updateErrorMessage?.('')
    updateErrorCode?.('')
    if (isSystemError) {
      await logout(router)
    }
  }

  router.onError((error) => {
    const errorString: string = error.toString()
    const regex = /dynamically imported module/
    if (regex.test(errorString)) {
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  })

  httpInterceptors(router)

</script>

<script setup lang="ts">
import type { Child } from '~~/types/child'

  const props = defineProps<{
    child: Child,
    selectFlg: boolean,
    settingFlg: boolean,
  }>()

  defineEmits<{
    (e: 'click'): void
  }>()

  const child = props.child
  const heightClass = 'h-12'
  const widthClass = 'w-12'

  const borderColor = props.selectFlg  ? 'common-border-leaf' : 'border-gray-400'
  const symbolColor = props.selectFlg  ? 'common-text-leaf' : 'text-gray-400'
</script>
<template>
  <div
    class="
      border-2
      flex
      p-4
      rounded-md
    "
    :class="borderColor"
    @click="$emit('click')"
  >
    <div
      class="
        mr-4
        relative
        text-center
      "
    >
      <common-unread-badge
        v-if="!settingFlg && props.child.unReadCount !== undefined ? props.child.unReadCount > 0 : false"
      />
      <common-avatar
        :img-str="child.avatar"
        :height-class="heightClass"
        :width-class="widthClass"
        round-size="medium"
      />
    </div>
    <div
      class="
        w-[calc(100%_-_80px)]
      "
    >
      <div
        class="
          font-bold
          truncate
        "
      >
        {{child.flnmFnm}} {{child.flnmLnm}}
        <span
          class="
            ml-1
            text-sm
          "
        >
          さん
        </span>
      </div>
      <div
        class="
          font-thin
          text-gray-400
          text-sm
          truncate
        "
      >
        {{child.orgNm}}
      </div>
      <div
        class="
          font-thin
          text-gray-400
          text-sm
          truncate
        "
      >
        {{ child.courseName }}
      </div>
    </div>
    <div
      v-if="!props.settingFlg"
      class="
        material-icons
        md-36
        pt-2
        w-4
      "
      :class="symbolColor"
    >
      chevron_right
    </div>
  </div>
</template>

import http from '~/http-common'
import type { AxiosResponse } from 'axios'
import { ApiPath } from '~/enum/apiPath'

class LogoutApiService {
  logout = async (): Promise<number> => {
    const path = ApiPath.LOGOUT_API_LOGOUT + '?skipRedirectFlg=1'
    const headers = {
      mode: 'cors',
      credentials: 'include',
    }
    const response: AxiosResponse = await http.get(path, { headers: headers })
    return response.status
  }
}

export default new LogoutApiService()
